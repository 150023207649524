import type {
  RouteLocationNormalized,
  NavigationGuardNext,
  RouteLocationRaw,
} from 'vue-router';
import { toRef } from 'vue';

import type { BaseRouteNameType } from '@leon-hub/routing-config-names';
import { RouteName } from '@leon-hub/routing-config-names';

import { useRegionPageStore } from 'web/src/modules/sportline/submodules/region/store';
import { useRedirectBySportsTree } from 'web/src/modules/sportline/submodules/redirects/composables';
import type { AppVueRouter } from 'web/src/modules/core/services/router/types';
import type { ConfigForLeagueLoading } from 'web/src/modules/sportline/submodules/league/types';
import { useRootNavigationStore } from 'web/src/modules/core/store';
import { getIsBrokenUrl } from 'web/src/modules/sportline/submodules/region/services/navigation/utils';
import type { LoadRegionConfig } from 'web/src/modules/sportline/submodules/region/types';
import { useLoadRegionConfig } from 'web/src/modules/sportline/submodules/region/composables';
import { getSportlinePageNavigationParameters } from 'web/src/modules/sportline/utils/navigation';
import { useSportlinePersistLocation } from 'web/src/modules/sportline/submodules/persist-filters';
import { useSportlineNavigationStore } from 'web/src/modules/sportline/submodules/navigation/store';

import { useBaseSportEventsRegionPageNavigationGuard } from './useBaseSportEventsRegionPageNavigationGuard';
import { useRegionNavigationBackButton } from './useRegionNavigationBackButton';

interface UseDefaultSportEventsRegionPageNavigationGuardComposable {
  prefetch(
    router: AppVueRouter,
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ): void;
}

export function useDefaultSportEventsRegionPageNavigationGuard(): UseDefaultSportEventsRegionPageNavigationGuardComposable {
  const regionPageStore = useRegionPageStore();
  const routeNavigationStore = useRootNavigationStore();
  const navigationStore = useSportlineNavigationStore();
  const isReady = toRef(regionPageStore, 'isReady');
  const currentRegionLoadingConfigId = toRef(regionPageStore, 'currentRegionLoadingConfigId');
  const baseRegionNavigationGuard = useBaseSportEventsRegionPageNavigationGuard();
  const sportlineNavigationBetweenPagesFilter = toRef(navigationStore, 'sportlineNavigationBetweenPagesFilter');

  const {
    getRedirectLocation,
    prefetch: basePrefetch,
  } = baseRegionNavigationGuard;

  const { setOnlyFirstRouteContentLoading, setRouteContentLoaded } = routeNavigationStore;
  const { activateBackButton } = useRegionNavigationBackButton();
  const {
    setConfigForRegionLoading,
    initialRequests,
    clearRegion,
    syncBackgroundRequests,
    awaitStateIsLoaded,
  } = regionPageStore;

  async function loadRegionForConfig(config: LoadRegionConfig, routeName: BaseRouteNameType): Promise<void> {
    setOnlyFirstRouteContentLoading(routeName);
    setConfigForRegionLoading(config);
    await initialRequests();
    setRouteContentLoaded(routeName);
  }

  function handleRedirectForWrongParameters(router: AppVueRouter, to: RouteLocationNormalized): void {
    const redirectTo = getRedirectLocation(router, to);
    if (redirectTo) { void router.pushRedirect(redirectTo, 301); }
  }

  async function handleRedirectsForEmptyPage(router: AppVueRouter, to: RouteLocationNormalized): Promise<void> {
    await awaitStateIsLoaded();

    if (isReady.value) {
      handleRedirectForWrongParameters(router, to);
      return;
    }

    const redirect = useRedirectBySportsTree({
      router,
      code: 301,
      allowedTargets: {
        sport: true,
        region: true,
      },
    });

    redirect.make();

    void regionPageStore.awaitLeavePage().then(() => {
      redirect.clearTimeout();
    });
  }

  function syncRegionForConfig(config: ConfigForLeagueLoading, routeName: BaseRouteNameType): void {
    if (config.filter) {
      clearRegion();
    }

    void syncBackgroundRequests({});
    setRouteContentLoaded(routeName);
  }

  function prefetch(
    router: AppVueRouter,
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ): void {
    basePrefetch(router, to, from, (superResult?: unknown) => {
      const { regionConfig } = useLoadRegionConfig({
        to: toRef(to),
        sportlineNavigationBetweenPagesFilter,
      });

      navigationStore.setSportlineNavigationBetweenPagesFilter(null);

      if (superResult !== undefined) {
        next(superResult as RouteLocationRaw);
        return;
      }

      if (getIsBrokenUrl(to)) {
        (useRedirectBySportsTree({
          router,
          code: 301,
          allowedTargets: {
            region: true,
          },
        })).redirect();
        return;
      }

      const navigationParameters = getSportlinePageNavigationParameters(to);

      const isLoadingNewRegion = !currentRegionLoadingConfigId.value
        || navigationParameters.regionId !== currentRegionLoadingConfigId.value;

      activateBackButton(navigationParameters);

      const { setPersistLocation } = useSportlinePersistLocation();
      setPersistLocation('sports', navigationParameters, 'region');

      if (!isReady.value || isLoadingNewRegion) {
        void loadRegionForConfig(regionConfig.value, RouteName.SPORT_REGION);
        void handleRedirectsForEmptyPage(router, to);
      } else {
        syncRegionForConfig(regionConfig.value, RouteName.SPORT_REGION);
        handleRedirectForWrongParameters(router, to);
      }

      next();
    });
  }

  return { prefetch };
}
